// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import { filter } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSVDownload, CSVLink } from "react-csv";
import CustomSnackBar from "src/components/CustomSnackBar";
// components
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
// mock
import { BASE_URL } from "../../network/constants";
import { UserListHead } from "../../sections/@dashboard/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "fullName", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "phoneNumber", label: "Phone", alignRight: false },
  { id: "occupation", label: "Occupation", alignRight: false },
  { id: "companyName", label: "Company", alignRight: false },
  { id: "pageName", label: "Page", alignRight: false },
  { id: "sources", label: "Sources", alignRight: false },
  { id: "isPresentOnHubspot", label: "Exists on Hubspot", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Leads() {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [leadsList, setLeadsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageFilter, setPageFilter] = useState("ALL");
  useEffect(() => {
    const fetchUserList = async () => {
      const leadsListRes = await axios.get(
        `${BASE_URL}/lead`,
        Cookies.get("token")
          ? {
              headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            }
          : null
      );

      setLeadsList(leadsListRes?.data?.Jobs);
    };

    fetchUserList();
  }, []);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downloading, setDownloading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const enableDownloading = () => {
    setDownloading(true);

    return setTimeout(() => {
      setDownloading(false);
    }, 800);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handlePasswordReset = async (id) => {
    try {
      const resetPassRes = await axios.get(
        `${BASE_URL}/auth/user/resetPassword?id=${id}`,
        Cookies.get("token")
          ? {
              headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            }
          : null
      );

      setOpen(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Category">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Leads List
          </Typography>

          <Stack direction="row">
            <Box mr={5}>
              <Button variant="contained" onClick={enableDownloading}>
                Download All
              </Button>
              {downloading ? (
                <Fragment>
                  <CSVLink data={leadsList}></CSVLink>
                  <CSVDownload data={leadsList} target="_blank" />
                </Fragment>
              ) : null}
            </Box>
            <TextField
              size="small"
              select
              label="Page Filter"
              SelectProps={{
                native: true,
              }}
              value={pageFilter}
              onChange={(e) => setPageFilter(e?.target?.value)}
              sx={{
                minWidth: 180,
              }}
            >
              <option key={"ALL"} value={"ALL"}>
                All
              </option>
              {[...new Set(leadsList?.map((item) => item?.pageName))]?.map(
                (pageName) => (
                  <option key={pageName} value={pageName}>
                    {pageName}
                  </option>
                )
              )}
            </TextField>
          </Stack>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  //   order={order}
                  //   orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={leadsList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  leftSpace={0}
                />
                <TableBody>
                  {leadsList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row) => {
                      const { _id, pageName } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;
                      return (
                        <TableRow hover key={_id} tabIndex={-1}>
                          {[
                            "fullName",
                            "email",
                            "phoneNumber",
                            "occupation",
                            "companyName",
                            "pageName",
                            "sources",
                            "isPresentOnHubspot",
                          ]?.map(
                            (item, index) =>
                              (pageFilter === "ALL" ||
                                pageFilter === pageName) && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={`${_id}-${index}-${item}`}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {item === "sources" ? (
                                      <Typography variant="subtitle2" noWrap>
                                        {row?.["utm_campaign"] ||
                                        row?.["utm_source"] ||
                                        row?.["utm_medium"] ||
                                        row?.["utm_id"] ? (
                                          <span>
                                            <b>utm_campaign:</b>{" "}
                                            {row?.["utm_campaign"]}, <br></br>
                                            <b>utm_source:</b>{" "}
                                            {row?.["utm_source"]},<br></br>
                                            <b>utm_medium:</b>{" "}
                                            {row?.["utm_medium"]}, <br></br>
                                            <b>utm_id:</b> {row?.["utm_id"]}
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    ) : item === "isPresentOnHubspot" ? (
                                      <Typography variant="subtitle2" noWrap>
                                        {row?.[item] ? "YES" : "NO"}
                                      </Typography>
                                    ) : (
                                      <Typography variant="subtitle2" noWrap>
                                        {row?.[item] === "" ? "-" : row?.[item]}
                                      </Typography>
                                    )}
                                  </Stack>
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={leadsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <CustomSnackBar
        open={open}
        setOpen={setOpen}
        title="Password Reset Successful!"
        severity="success"
      />
    </Page>
  );
}
