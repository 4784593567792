import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Blog",
    path: "/dashboard/blog",
    icon: getIcon("material-symbols:article"),
  },
  {
    title: "Blog Category",
    path: "/dashboard/category",
    icon: getIcon("material-symbols:category"),
  },
  {
    title: "FAQs",
    path: "/dashboard/faqs",
    icon: getIcon("material-symbols:question-mark"),
  },
  {
    title: "Leads",
    path: "/dashboard/leads",
    icon: getIcon("material-symbols:query-stats"),
  },
  {
    title: "Manage Admin",
    path: "/dashboard/manage-admin",
    icon: getIcon("material-symbols:admin-panel-settings"),
  },
];

export default navConfig;
