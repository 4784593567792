import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import Cookies from "js-cookie";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "src/network/constants";
import * as Yup from "yup";
import Page from "../../components/Page";

const AddNewFaqSchema = Yup.object().shape({
  category: Yup.string().trim().min(1).required("Category is required!"),
  title: Yup.string().trim().min(3).required("Title is required!"),
  description: Yup.string().trim().min(3).required("Description is required!"),
});

const CATEGORY_LIST = [
  {
    id: "1",
    label: "About ScanPay",
    value: "About ScanPay",
  },
  {
    id: "2",
    label: "Getting Started",
    value: "Getting Started",
  },
  {
    id: "3",
    label: "Collecting Payments",
    value: "Collecting Payments",
  },
  {
    id: "4",
    label: "ScanPay Banking",
    value: "ScanPay Banking",
  },
  {
    id: "5",
    label: "Tap to Pay on iPhone",
    value: "Tap to Pay on iPhone",
  },
  {
    id: "6",
    label: "Tap to Pay on Android",
    value: "Tap to Pay on Android",
  },
  {
    id: "7",
    label: "ScanPay Web",
    value: "ScanPay Web",
  },
  {
    id: "8",
    label: "Disputes",
    value: "Disputes",
  },
];

export default function AddNewFaq() {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: location?.state?.title ?? "",
      category: location?.state?.category ?? CATEGORY_LIST?.[0]?.value,
      description: location?.state?.description ?? "",
      videoUrl: location?.state?.videoUrl ?? "",
    },
    validationSchema: AddNewFaqSchema,
    onSubmit: async (values) => {
      try {
        let FaqAddRes = "";

        let reqValues = values;

        if (values?.videoUrl === "" || values?.videoUrl === null) {
          delete reqValues.videoUrl;
        }
        if (location?.state?.title) {
          FaqAddRes = await axios.post(
            `${BASE_URL}/faq/update?id=${location?.state?.id}`,
            reqValues,
            Cookies.get("token")
              ? {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                  },
                }
              : null
          );
        } else {
          FaqAddRes = await axios.post(
            `${BASE_URL}/faq`,
            reqValues,
            Cookies.get("token")
              ? {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                  },
                }
              : null
          );
        }

        if (FaqAddRes?.data?.success) {
          setOpen(true);
          return navigate("/dashboard/faqs");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Add FAQ">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Add FAQ
          </Typography>
        </Stack>

        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                size="small"
                fullWidth
                label="Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                sx={{ mb: 3 }}
              />

              <TextField
                size="small"
                select
                fullWidth
                label="Category"
                SelectProps={{
                  native: true,
                }}
                {...getFieldProps("category")}
                error={Boolean(touched.category && errors.category)}
                helperText={touched.category && errors.category}
                sx={{ mb: 2 }}
              >
                {CATEGORY_LIST?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              {/* <TextField
                size="small"
                fullWidth
                label="Category"
                {...getFieldProps("category")}
                error={Boolean(touched.category && errors.category)}
                helperText={touched.category && errors.category}
                sx={{ mb: 3 }}
              /> */}
              <TextField
                size="small"
                fullWidth
                label="Video Url"
                {...getFieldProps("videoUrl")}
                error={Boolean(touched.videoUrl && errors.videoUrl)}
                helperText={touched.videoUrl && errors.videoUrl}
                sx={{ mb: 3 }}
              />

              <Box sx={{ mb: 2 }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    location?.state?.title
                      ? values?.description
                      : "<p>Description...</p>"
                  }
                  // onReady={(editor) => {
                  //   // You can store the "editor" and use when it is needed.
                  //   // console.log("Editor is ready to use!", editor);
                  // }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("description", data);
                  }}
                  // onBlur={(event, editor) => {
                  //   // console.log("Blur.", editor);
                  // }}
                  // onFocus={(event, editor) => {
                  //   // console.log("Focus.", editor);
                  // }}
                />
              </Box>

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 5 }}
              >
                Submit
              </LoadingButton>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 5, ml: 4 }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Form>
          </FormikProvider>
        </Stack>

        <Snackbar
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            FAQ Successfully Added!
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
