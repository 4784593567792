import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import ThemeProvider from "./theme";

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
