import {
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  IconButton,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { BASE_URL } from "../../../network/constants";
import { fDate } from "../../../utils/formatTime";
import BlogDetailDialog from "./BlogDetailDialog";

const CardMediaStyle = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: "hidden",
  WebkitLineClamp: 2,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
});

const CoverImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard({ post, setBlogList, index }) {
  const [open, setOpen] = useState(false);

  const {
    _id,
    status,
    category,
    imgUrl,
    publishedDate,
    longDesc,
    summary,
    title,
    slug,
    metaTite,
    metaDesc,
    metaKeywords,
    bannerImgAlt,
  } = post;

  const handleStatusUpdate = async (id, status) => {
    try {
      await axios.post(
        `${BASE_URL}/blog/update/status?id=${id}`,
        {
          status,
        },
        Cookies.get("token")
          ? {
              headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            }
          : null
      );

      const blogRes = await axios.get(`${BASE_URL}/blog`);
      setBlogList(blogRes?.data?.blogs ?? []);

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} key={_id}>
      <Card sx={{ position: "relative" }}>
        <CardMediaStyle>
          <CoverImgStyle alt={title} src={imgUrl} />
        </CardMediaStyle>

        <CardContent
          onClick={() => setOpen(true)}
          sx={{
            pt: 4,
            cursor: "pointer",
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: "text.disabled", display: "block" }}
          >
            {publishedDate
              ? new Date(publishedDate)?.toLocaleDateString("en-US", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })
              : fDate(new Date())}
          </Typography>

          <TitleStyle
            to="#"
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
          >
            {title}
          </TitleStyle>

          <IconButton onClick={() => {}} size="small" sx={{ marginTop: 1 }}>
            <Iconify icon="eva:info-outline" />
          </IconButton>
        </CardContent>
      </Card>
      {open && (
        <BlogDetailDialog
          open={open}
          setOpen={setOpen}
          id={_id}
          status={status}
          category={category}
          imgUrl={imgUrl}
          publishedDate={publishedDate}
          longDesc={longDesc}
          summary={summary}
          title={title}
          slug={slug}
          metaTite={metaTite}
          metaDesc={metaDesc}
          metaKeywords={metaKeywords}
          bannerImgAlt={bannerImgAlt}
          handleStatusUpdate={handleStatusUpdate}
        />
      )}
    </Grid>
  );
}
