import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import { BASE_URL } from "./network/constants";
import AddNewBlog from "./pages/AddNewBlog";
import Blog from "./pages/Blog";
import Category from "./pages/category/category";
import CategoryForm from "./pages/category/form";
import Faqs from "./pages/faqs/Faqs";
import AddNewFaq from "./pages/faqs/form";
import JobApplication from "./pages/jobApplication";
import Leads from "./pages/leads";
import Login from "./pages/Login";
import AddAdmin from "./pages/manage-admin/AddAdmin";
import ManageAdmin from "./pages/manage-admin/ManageAdmin";
import NotFound from "./pages/Page404";

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();

  useEffect(() => {
    const authCheck = async () => {
      try {
        const authRes = await axios.get(
          `${BASE_URL}/auth/authCheck`,
          Cookies.get("token")
            ? {
                headers: {
                  Authorization: `Bearer ${Cookies.get("token")}`,
                },
              }
            : null
        );

        if (authRes?.data?.success) {
          navigate("/dashboard/blog");
        }
      } catch (error) {
        console.log(error);

        if (error?.response?.status === 401) {
          navigate("/login");
        }
      }
    };

    authCheck();
  }, []);

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "blog", element: <Blog /> },
        { path: "faqs", element: <Faqs /> },
        { path: "category", element: <Category /> },
        { path: "category/add", element: <CategoryForm /> },
        { path: "job/application", element: <JobApplication /> },
        { path: "blog/add", element: <AddNewBlog /> },
        { path: "faqs/add", element: <AddNewFaq /> },
        { path: "manage-admin", element: <ManageAdmin /> },
        { path: "manage-admin/add", element: <AddAdmin /> },
        { path: "leads", element: <Leads /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        { path: "login", element: <Login /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" /> },
  ]);
}
