import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Iconify from "../../components/Iconify";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import { BASE_URL } from "../../network/constants";

export default function User() {
  const [list, setList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFAQList = async () => {
      const faqList = await axios.get(`${BASE_URL}/faq/list`);

      setList(
        faqList?.data?.allFaq?.sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        )
      );
    };

    fetchFAQList();
  }, []);

  const deleteFAQHandler = async (id) => {
    await axios.delete(
      `${BASE_URL}/faq?id=${id}`,
      Cookies.get("token")
        ? {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        : null
    );

    const freshFAQList = await axios.get(`${BASE_URL}/faq/list`);
    setList(freshFAQList?.data?.allFaq);
  };

  return (
    <Page title="FAQs">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            FAQs
          </Typography>

          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/faqs/add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New FAQ
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            {list?.map((row) => (
              <Accordion
                key={row?._id}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box width="100%">
                    <Typography>
                      <b>Title:</b> {row?.title}
                    </Typography>
                  </Box>

                  <Box width="100%">
                    <Typography sx={{ ml: 10 }}>
                      <b>Category:</b> {row?.category}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>Video:</b> {row?.videoUrl}
                  </Typography>
                  <Typography>
                    <b>Description:</b>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: row?.description }}
                    ></span>
                  </Typography>
                </AccordionDetails>
                <AccordionActions>
                  <Button onClick={() => deleteFAQHandler(row?._id)}>
                    Delete
                  </Button>
                  <Button
                    onClick={() =>
                      navigate("/dashboard/faqs/add", {
                        state: {
                          id: row?._id,
                          title: row?.title,
                          category: row?.category,
                          videoUrl: row?.videoUrl,
                          description: row?.description,
                        },
                      })
                    }
                  >
                    Update
                  </Button>
                </AccordionActions>
              </Accordion>
            ))}
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
