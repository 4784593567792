import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlogDetailDialog({
  open,
  setOpen,
  id,
  status,
  category,
  imgUrl,
  publishedDate,
  summary,
  longDesc,
  title,
  slug,
  metaTite,
  metaDesc,
  metaKeywords,
  handleStatusUpdate,
  bannerImgAlt
}) {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        scroll={"paper"}
        maxWidth={"md"}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>{title}</Box>
            <IconButton onClick={handleClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Status</b>: {status}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Published Date</b>:{" "}
            {new Date(publishedDate)?.toLocaleDateString()}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Category</b>: {category?.title}
          </DialogContentText>

          <DialogContentText id="alert-dialog-slide-description">
            <b>Blog URL/Slug</b>: {slug}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Meta Tite</b>: {metaTite}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Meta Description </b>: {metaDesc}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Meta Keywords</b>: {metaKeywords}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Featured Image Alt</b>: {bannerImgAlt}
          </DialogContentText>

          <DialogContentText id="alert-dialog-slide-description">
            <b>Details: </b>
          </DialogContentText>
          <Box m={2}>
            <DialogContentText id="alert-dialog-slide-description">
              <img
                src={imgUrl}
                width="auto"
                style={{ maxHeight: "300px" }}
              ></img>
            </DialogContentText>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <span dangerouslySetInnerHTML={{ __html: longDesc }}></span>
          </DialogContentText>
          <br></br>
          <Box mt={3} mb={1}>
            <DialogContentText id="alert-dialog-slide-description">
              <b>Summary: </b>
            </DialogContentText>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <span dangerouslySetInnerHTML={{ __html: summary }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleStatusUpdate(
                id,
                status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
              )
            }
          >
            Make {status === "ACTIVE" ? "InActive" : "Active"}
          </Button>
          <Button
            onClick={() => {
              navigate("/dashboard/blog/add", {
                state: {
                  id,
                  status,
                  category,
                  title,
                  imgUrl,
                  publishedDate,
                  summary,
                  longDesc,
                  blogUrl: slug,
                  metaTite,
                  metaDesc,
                  metaKeywords,
                  bannerImgAlt
                },
              });
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
