import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";

export default function CustomSnackBar({
  open = false,
  setOpen,
  title = "",
  severity = "success",
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          background:
            severity === "error" ? "#d32f2f !important" : "#43A047 !important",
        }}
      >
        <Box
          sx={{
            color: "#fff",
          }}
        >
          {title}
        </Box>
      </Alert>
    </Snackbar>
  );
}
