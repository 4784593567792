import { LoadingButton } from "@mui/lab";
import { Container, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "src/network/constants";
import * as Yup from "yup";
import Page from "../../components/Page";

const AddNewCategorySchema = Yup.object().shape({
  title: Yup.string().trim().min(3).required("Title is required!"),
});

export default function AddNewCategory() {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: location?.state?.title ?? "",
    },
    validationSchema: AddNewCategorySchema,
    onSubmit: async (values) => {
      try {
        let categoryAddRes = "";

        if (location?.state?.title) {
          categoryAddRes = await axios.post(
            `${BASE_URL}/blog/category/update?id=${location?.state?.id}`,
            values,
            Cookies.get("token")
              ? {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                  },
                }
              : null
          );
        } else {
          categoryAddRes = await axios.post(
            `${BASE_URL}/blog/category`,
            values,
            Cookies.get("token")
              ? {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                  },
                }
              : null
          );
        }

        if (categoryAddRes?.data?.success) {
          setOpen(true);
          return navigate("/dashboard/category");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Dashboard: Category">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Add Category
          </Typography>
        </Stack>

        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                size="small"
                fullWidth
                label="Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                sx={{ mb: 3 }}
              />

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 5 }}
              >
                Submit
              </LoadingButton>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 5, ml: 4 }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Form>
          </FormikProvider>
        </Stack>

        <Snackbar
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Category Successfully Added!
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
