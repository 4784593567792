import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { alpha } from "@mui/material/styles";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuPopover from "../../components/MenuPopover";

export default function AccountPopover() {
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userDetails = Cookies.get("user");
    if (userDetails) {
      setUser(JSON.parse(userDetails));
    }
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    setOpen(null);
    navigate("/login");
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={"/static/mock-images/avatars/avatar_default.jpg"}
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
