import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "src/network/constants";
import * as Yup from "yup";
import Page from "../../components/Page";

const AddNewCategorySchema = Yup.object().shape({
  name: Yup.string().trim().min(3).required("Name is required!"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  userType: Yup.string().trim().min(1).required("User Type is required!"),
});

const USER_ROLES = [
  {
    label: "Admin (Full Access)",
    value: "ADMIN",
  },
  {
    label: "Blog (Only Blog Access)",
    value: "BLOG",
  },
];

export default function AddAdmin() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      userType: USER_ROLES?.[0]?.value,
    },
    validationSchema: AddNewCategorySchema,
    onSubmit: async (values) => {
      try {
        const adminAddRes = await axios.post(
          `${BASE_URL}/auth/new/user`,
          values,
          Cookies.get("token")
            ? {
                headers: {
                  Authorization: `Bearer ${Cookies.get("token")}`,
                },
              }
            : null
        );

        if (adminAddRes?.data?.success) {
          setOpen(true);
          return navigate("/dashboard/manage-admin");
        }
      } catch (error) {
        setError(
          error?.response?.data?.message ?? "Oops! Something went wrong!"
        );
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Dashboard: Add Admin">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Add New Admin
          </Typography>
        </Stack>

        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                size="small"
                select
                fullWidth
                label="User Type (Access)"
                SelectProps={{
                  native: true,
                }}
                {...getFieldProps("userType")}
                error={Boolean(touched.userType && errors.userType)}
                helperText={touched.userType && errors.userType}
                sx={{ mb: 2 }}
              >
                {USER_ROLES?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <TextField
                size="small"
                fullWidth
                label="Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{ mb: 3 }}
              />

              <TextField
                size="small"
                fullWidth
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                sx={{ mb: 3 }}
              />

              <Box
                sx={{
                  width: "100%",
                  color: "red",
                }}
              >
                {error}
              </Box>

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 5 }}
              >
                Submit
              </LoadingButton>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 5, ml: 4 }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Form>
          </FormikProvider>
        </Stack>

        <Snackbar
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Category Successfully Added!
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
