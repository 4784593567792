import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { BlogPostCard } from "src/sections/@dashboard/blog";
import Iconify from "../components/Iconify";
import Page from "../components/Page";
import { BASE_URL } from "../network/constants";

// ----------------------------------------------------------------------

export default function Blogs() {
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogRes = await axios.get(`${BASE_URL}/blog`);
        setBlogList(blogRes?.data?.blogs ?? []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <Page title="Blog">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Blogs/News
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/blog/add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Blog/News
          </Button>
        </Stack>

        <Grid container spacing={3}>
          {blogList?.map(
            (post, index) => (
              { index },
              (
                <BlogPostCard
                  key={post._id}
                  post={post}
                  index={index}
                  setBlogList={setBlogList}
                />
              )
            )
          )}
        </Grid>
      </Container>
    </Page>
  );
}
