import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSnackBar from "src/components/CustomSnackBar";
import * as Yup from "yup";
import Page from "../components/Page";
import { BASE_URL } from "../network/constants";

const AddNewBlogSchema = Yup.object().shape({
  category: Yup.string().trim().min(1).required("Category is required!"),
  title: Yup.string().trim().min(3).required("Title is required!"),
  blogUrl: Yup.string().trim().min(1).required("Blog URL is required!"),
  metaTite: Yup.string().trim().min(1).required("Meta Title is required!"),
  metaDesc: Yup.string()
    .trim()
    .min(1)
    .required("Meta Description is required!"),
  metaKeywords: Yup.string()
    .trim()
    .min(1)
    .required("Meta Keywords are required!"),
  publishedDate: Yup.date()
    .max(new Date())
    .required("Publish Date is required!"),
  bannerImgAlt: Yup.string()
    .trim()
    .min(1)
    .required("Alt Text for Featured Image is required!"),
});

const EDITOR_CONFIG = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "blockQuote",
    "link",
    "numberedList",
    "bulletedList",
    "imageUpload",
    "insertTable",
    "mergeTableCells",
    "|",
    "undo",
    "redo",
  ],

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
};

export default function AddNewBlog() {
  const navigate = useNavigate();
  const location = useLocation();

  const [categories, setCategories] = useState();
  const [bannerImg, setBannerImg] = useState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const catRes = await axios.get(`${BASE_URL}/blog/category`);
        setCategories(catRes?.data?.data ?? []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      title: location?.state?.title ?? "",
      blogUrl: location?.state?.blogUrl ?? "",
      metaTite: location?.state?.metaTite ?? "",
      metaDesc: location?.state?.metaDesc ?? "",
      metaKeywords: location?.state?.metaKeywords ?? "",
      category: location?.state?.category?._id ?? categories?.[0]?._id,
      publishedDate: location?.state?.publishedDate
        ? new Date(location?.state?.publishedDate)
        : new Date(),
      longDesc: location?.state?.longDesc ?? "",
      shortDesc: location?.state?.summary ?? "",
      bannerImgAlt: location?.state?.bannerImgAlt ?? "",
    },
    validationSchema: AddNewBlogSchema,
    onSubmit: async (values) => {
      try {
        let formData = new FormData();
        formData.append("title", values?.title);
        formData.append("blogUrl", values?.blogUrl);
        formData.append("metaTite", values?.metaTite);
        formData.append("metaDesc", values?.metaDesc);
        formData.append("metaKeywords", values?.metaKeywords);
        formData.append("category", values?.category);
        formData.append("date", values?.publishedDate);
        formData.append("longDesc", values?.longDesc);
        formData.append("shortDesc", values?.shortDesc);
        formData.append("bannerImgAlt", values?.bannerImgAlt);

        let blogAddRes = "";
        if (location?.state?.title) {
          if (bannerImg) {
            formData.append("imgUrl", bannerImg);
          } else {
            formData.append("imgUrl", location?.state?.imgUrl);
          }

          blogAddRes = await axios.post(
            `${BASE_URL}/blog/update?id=${location?.state?.id}`,
            formData,
            Cookies.get("token")
              ? {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                  },
                }
              : null
          );
        } else {
          formData.append("imgUrl", bannerImg);
          blogAddRes = await axios.post(
            `${BASE_URL}/blog/add`,
            formData,
            Cookies.get("token")
              ? {
                  headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                  },
                }
              : null
          );
        }

        if (blogAddRes?.data?.success) {
          setOpen(true);
          return navigate("/dashboard/blog");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleBannerChange = (e) => {
    setBannerImg(e.target.files[0]);
  };

  return (
    <Page title="Dashboard: Blog">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Add New Blog
          </Typography>
        </Stack>

        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                size="small"
                select
                fullWidth
                label="Category"
                SelectProps={{
                  native: true,
                }}
                {...getFieldProps("category")}
                error={Boolean(touched.category && errors.category)}
                helperText={touched.category && errors.category}
                sx={{ mb: 2 }}
              >
                {categories?.[0] &&
                  categories?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.title}
                    </option>
                  ))}
              </TextField>

              <TextField
                size="small"
                fullWidth
                label="Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                sx={{ mb: 3 }}
              />

              <TextField
                size="small"
                fullWidth
                label="Blog URL"
                {...getFieldProps("blogUrl")}
                error={Boolean(touched.blogUrl && errors.blogUrl)}
                helperText={touched.blogUrl && errors.blogUrl}
                sx={{ mb: 3 }}
              />

              <TextField
                size="small"
                fullWidth
                label="Meta Title"
                {...getFieldProps("metaTite")}
                error={Boolean(touched.metaTite && errors.metaTite)}
                helperText={touched.metaTite && errors.metaTite}
                sx={{ mb: 3 }}
              />

              <TextField
                size="small"
                fullWidth
                label="Meta Description"
                {...getFieldProps("metaDesc")}
                error={Boolean(touched.metaDesc && errors.metaDesc)}
                helperText={touched.metaDesc && errors.metaDesc}
                sx={{ mb: 3 }}
              />

              <TextField
                size="small"
                fullWidth
                label="Meta Keywords - Enter comma (,) separated values"
                {...getFieldProps("metaKeywords")}
                error={Boolean(touched.metaKeywords && errors.metaKeywords)}
                helperText={touched.metaKeywords && errors.metaKeywords}
                sx={{ mb: 3 }}
              />

              <DatePicker
                disableFuture
                size="small"
                label="Published Date"
                {...getFieldProps("publishedDate")}
                onChange={(newValue) => {
                  setFieldValue("publishedDate", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    error={Boolean(
                      touched.publishedDate && errors.publishedDate
                    )}
                    helperText={touched.publishedDate && errors.publishedDate}
                    sx={{ mb: 3 }}
                  />
                )}
              />
              <Box sx={{ mb: 3 }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple={false}
                  type="file"
                  onChange={handleBannerChange}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    component="span"
                    color="primary"
                    sx={{
                      background: "#e8f0f8",
                      marginRight: "16px",
                    }}
                  >
                    Add Featured Image
                  </Button>
                </label>
                <span>{bannerImg?.name}</span>
              </Box>
              <TextField
                size="small"
                fullWidth
                label="Alt Text for Featured Image"
                {...getFieldProps("bannerImgAlt")}
                error={Boolean(touched.bannerImgAlt && errors.bannerImgAlt)}
                helperText={touched.bannerImgAlt && errors.bannerImgAlt}
                sx={{ mb: 3 }}
              />
              <Box sx={{ mb: 2 }}>
                <CKEditor
                  editor={ClassicEditor}
                  config={EDITOR_CONFIG}
                  data={location?.state?.longDesc ?? "<p>Description...</p>"}
                  // onReady={(editor) => {
                  //   // You can store the "editor" and use when it is needed.
                  //   // console.log("Editor is ready to use!", editor);
                  // }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("longDesc", data);
                  }}
                  // onBlur={(event, editor) => {
                  //   // console.log("Blur.", editor);
                  // }}
                  // onFocus={(event, editor) => {
                  //   // console.log("Focus.", editor);
                  // }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <CKEditor
                  editor={ClassicEditor}
                  config={EDITOR_CONFIG}
                  data={
                    location?.state?.summary ?? "<p>Short Description...</p>"
                  }
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("shortDesc", data);
                  }}
                />
              </Box>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 5 }}
              >
                Submit
              </LoadingButton>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 5, ml: 4 }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Form>
          </FormikProvider>
        </Stack>
        {open && (
          <CustomSnackBar
            open={open}
            setOpen={setOpen}
            title="Blog Added Successfully!"
            severity="success"
          />
        )}
      </Container>
    </Page>
  );
}
